import React, { useEffect } from "react";
import { get, useFormContext, useWatch } from "react-hook-form";
import { css } from "@emotion/react";
import { SelectBlock } from "main/javascripts/components/form/SelectBlock";
import { moreThanBreakpoint } from "../../../styles/base/responsiveStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
// import {accentColor} from "../../../styles/base/colorStyle";
// import {fontWeight} from "../../../styles/base/typographyStyle";

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  groupName: string;
  index: number;
  maxAgeOfChildren: number;
  numberOfRooms: number;
  ageInputWidthStyle?: string;
  borderColorStyleKey?: keyof IBorderColorStyle;
  errors?: any;
}

export const GuestChildrenAgeField: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    groupName,
    index,
    maxAgeOfChildren,
    numberOfRooms,
    ageInputWidthStyle = "33.333%",
    borderColorStyleKey,
    errors,
  } = props;

  const fieldArrayName = `${groupName}.ages_of_children`;
  const fieldName = `${fieldArrayName}.${index}.age`;

  const { control, trigger } = useFormContext();
  const ageValue = useWatch({
    control,
    name: fieldName,
  });

  useEffect(() => {
    if (ageValue) {
      void trigger(fieldName);
    }
  }, [ageValue]);

  const optionList: React.ReactElement[] = [
    <option key="none" value="" disabled={true}>
      年齢
    </option>,
  ];

  const ageInputStyles: any =
    Number(numberOfRooms || 1) === 1
      ? null
      : css`
        ${moreThanBreakpoint("desktop")} {
          width: ${ageInputWidthStyle};
        },
      `;

  // tslint:disable-next-line:prefer-array-literal
  Array.from(Array(maxAgeOfChildren + 1).keys()).map((childAge: number) => {
    optionList.push(
      <option key={childAge} value={childAge}>{`${childAge} 歳`}</option>
    );
  });

  return (
    <div css={[guestInputBlockSmallStyle, ageInputStyles]}>
      <SelectBlock
        namespace="hotel"
        label={{
          namespace: "hotel",
          label: `お子様 ${Number(index) + 1}の年齢`,
          colorStyleKey: "primary",
        }}
        select={{
          name: fieldName,
          children: optionList,
        }}
        error={get(errors, fieldName)}
        borderColorStyleKey={borderColorStyleKey}
      />
    </div>
  );
};

const guestInputBlockSmallStyle = css`
  width: 33.333%;
  ${moreThanBreakpoint("tablet")} {
    width: 25%;
  }
`;
// const childLabelStyle = css`
//   color: ${accentColor.primaryColor};
//   font-weight: ${fontWeight.regular};
// `;
