import * as React from "react";
import { css } from "@emotion/react";
import { SelectBlock } from "main/javascripts/components/form/SelectBlock";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { get } from "react-hook-form";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  groupName: string;
  maxNumberOfChildren: number;
  maxNumberOfAdult: number;
  onChange: any;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  errors?: any;
}

const GuestPeopleBlockComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    groupName,
    maxNumberOfChildren,
    maxNumberOfAdult,
    labelColorStyleKey = "default",
    borderColorStyleKey = "none",
    errors,
  } = props;

  const renderNumberOfChildren = (): React.ReactElement => {
    const optionList: React.ReactElement[] = [];

    // tslint:disable-next-line:prefer-array-literal
    Array.from(Array(maxNumberOfChildren + 1).keys()).map(
      (childNum: number) => {
        optionList.push(
          <option key={childNum} value={childNum}>{`${childNum} 人`}</option>
        );
      }
    );

    const fieldName = `${groupName}.number_of_children`;

    return (
      <div css={guestInputBlockStyle}>
        <SelectBlock
          namespace="hotel"
          label={{
            namespace: "common",
            label: "子供(0～17歳)",
            colorStyleKey: labelColorStyleKey,
          }}
          select={{
            name: fieldName,
            children: optionList,
          }}
          error={get(errors, fieldName)}
          borderColorStyleKey={borderColorStyleKey}
        />
      </div>
    );
  };
  const renderNumberOfAdult = (): React.ReactElement => {
    const optionList: React.ReactElement[] = [];

    // tslint:disable-next-line:prefer-array-literal
    Array.from(Array(maxNumberOfAdult).keys()).map((numberOfAdult: number) => {
      optionList.push(
        <option key={numberOfAdult} value={numberOfAdult + 1}>
          {`${numberOfAdult + 1} 人`}
        </option>
      );
    });

    const fieldName = `${groupName}.number_of_adult`;
    return (
      <div css={guestInputBlockStyle}>
        <SelectBlock
          namespace="hotel"
          label={{
            namespace: "common",
            label: "大人(18歳以上)",
            colorStyleKey: labelColorStyleKey,
          }}
          select={{
            name: fieldName,
            children: optionList,
          }}
          error={get(errors, fieldName)}
          borderColorStyleKey={borderColorStyleKey}
        />
      </div>
    );
  };
  return (
    <>
      {renderNumberOfAdult()}
      {renderNumberOfChildren()}
    </>
  );
};
export const GuestPeopleBlock: any = React.memo(GuestPeopleBlockComponent);

const guestInputBlockStyle = css`
  width: 50%;
`;
