import React, { useEffect } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { css } from "@emotion/react";
import { rangexy } from "main/javascripts/utils/ArrayUtil";
import { GuestChildrenAgeField } from "./GuestChildrenAgeField";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  groupName: string;
  maxAgeOfChildren: number;
  numberOfRooms: number;
  ageInputWidthStyle?: string;
  borderColorStyleKey?: keyof IBorderColorStyle;
  errors?: any;
}

const GuestChildrenBlockComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    groupName,
    maxAgeOfChildren,
    numberOfRooms,
    ageInputWidthStyle = "33.333%",
    borderColorStyleKey,
    errors,
  } = props;

  const fieldArrayName = `${groupName}.ages_of_children`;

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: fieldArrayName,
  });
  const numberOfChildrenValue = useWatch({
    control,
    name: `${groupName}.number_of_children`,
  });

  // useFieldArrayではオブジェクト単位で追加する必要があり
  // indexで終わるkeyに対して値を追加できないため
  // .ageで管理し、リクエスト時に整形する
  useEffect(() => {
    const oldNumberOfChildren = fields.length || 0;
    const newNumberOfChildren = Number(numberOfChildrenValue);
    // setValue(`guests[${index}].number_of_children`, childNum);
    if (oldNumberOfChildren > newNumberOfChildren) {
      remove(rangexy(newNumberOfChildren, oldNumberOfChildren));
    } else if (oldNumberOfChildren < newNumberOfChildren) {
      rangexy(oldNumberOfChildren, newNumberOfChildren).map((_: number) => {
        append({ age: "" }, { shouldFocus: false });
      });
    }
  }, [numberOfChildrenValue]);

  return (
    <div css={guestInputGroupBlockStyle}>
      {fields.map((field, index) => (
        <GuestChildrenAgeField
          key={field.id}
          groupName={groupName}
          index={index}
          maxAgeOfChildren={maxAgeOfChildren}
          numberOfRooms={numberOfRooms}
          ageInputWidthStyle={ageInputWidthStyle}
          borderColorStyleKey={borderColorStyleKey}
          errors={errors}
        />
      ))}
    </div>
  );
};
export const GuestChildrenBlock: any = React.memo(GuestChildrenBlockComponent);

const guestInputGroupBlockStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
