import React, { useRef } from "react";
import { css } from "@emotion/react";
import { get, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { SelectBlock } from "main/javascripts/components/form/SelectBlock";
import { rangexy } from "main/javascripts/utils/ArrayUtil";
import isEqual from "react-fast-compare";
import { GuestPeopleBlock } from "main/javascripts/components/hotel/form/GuestPeopleBlock";
import { GuestChildrenBlock } from "main/javascripts/components/hotel/form/GuestChildrenBlock";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { IColorStyle } from "../form/Label";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";

export interface IProps {
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  isGuestMultipleColumn?: boolean;
}

const MAX_NUMBER_OF_ROOMS = 8;
const MAX_NUMBER_OF_ADULT = 7;
const MAX_NUMBER_OF_CHILDREN = 7;
const MAX_AGE_OF_CHILDREN = 17;

const initialRoomValue = {
  number_of_adult: 2,
  number_of_children: 0,
  ages_of_children: [],
};

const GuestsBlockComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    labelColorStyleKey,
    borderColorStyleKey,
    isGuestMultipleColumn = true,
  } = props;

  // public shouldComponentUpdate(nextProps: any): boolean {
  //   return !isEqual(props, nextProps);
  // }

  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const values = useWatch();
  const { fields, append, remove } = useFieldArray({
    name: "guests",
  });

  const valuesRef = useRef(values);
  valuesRef.current = values;

  const renderNumberOfRooms: () => React.ReactElement = () => {
    const optionList: React.ReactElement[] = [];
    // const { styles = {} } = props;
    // const { labelStyle } = styles;

    const fieldName = "number_of_rooms";

    // tslint:disable-next-line:prefer-array-literal
    Array.from(Array(MAX_NUMBER_OF_ROOMS).keys()).map((num: number) => {
      optionList.push(
        <option
          key={num}
          value={num + 1}
          role="option"
          aria-selected={num === 0}
        >{`${num + 1} 室`}</option>
      );
    });
    return (
      <div css={[guestInputBlockStyle, guestNumBlockStyle]}>
        <SelectBlock
          namespace="hotel"
          label={{
            namespace: "hotel",
            label: "客室数",
            colorStyleKey: labelColorStyleKey,
          }}
          select={{
            name: fieldName,
            onChange: onChangeNumberOfRooms,
            children: optionList,
          }}
          error={get(errors, fieldName)}
          borderColorStyleKey={borderColorStyleKey}
        />
      </div>
    );
  };

  const onChangeNumberOfRooms: (e: any) => void = (e: any) => {
    const oldNumberOfRooms: number = parseInt(
      valuesRef.current.number_of_rooms,
      10
    );
    const numberOfRooms: number = parseInt(e.target.value, 10);
    setValue("number_of_rooms", numberOfRooms);
    if (oldNumberOfRooms > numberOfRooms) {
      remove(rangexy(numberOfRooms, oldNumberOfRooms));
    } else if (oldNumberOfRooms < numberOfRooms) {
      rangexy(oldNumberOfRooms, numberOfRooms).map((_: number) => {
        append(initialRoomValue, { shouldFocus: false });
      });
    }
  };

  const renderGuestsFields = () => {
    return (
      <React.Fragment key={0}>
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <div css={guestInputGroupBlockMediumStyle} key="people">
              <GuestPeopleBlock
                groupName={`guests.${index}`}
                maxNumberOfChildren={MAX_NUMBER_OF_CHILDREN}
                maxNumberOfAdult={MAX_NUMBER_OF_ADULT}
                labelColorStyleKey={labelColorStyleKey}
                borderColorStyleKey={borderColorStyleKey}
                errors={errors}
              />
            </div>
            <div css={guestInputGroupBlockStyle} key="childs">
              <GuestChildrenBlock
                groupName={`guests.${index}`}
                maxAgeOfChildren={MAX_AGE_OF_CHILDREN}
                numberOfRooms={values.number_of_rooms}
                ageInputWidthStyle={isGuestMultipleColumn ? "25%" : null}
                borderColorStyleKey={borderColorStyleKey}
                errors={errors}
              />
            </div>
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  const renderGuestsFieldsWithMulti = () => {
    // const { styles = {} } = props;
    // const { guestItemStyle } = styles;
    const guestInputGroupBlockStyles = isGuestMultipleColumn
      ? guestInputGroupForMultiBlockStyle
      : guestInputGroupBlockStyle;
    return (
      <React.Fragment key={0}>
        {fields.map((field, index) => (
          <div key={field.id} css={guestInputGroupBlockStyles}>
            <p css={guestLabelStyle}>
              <span>客室 {index + 1}</span>
            </p>
            <div css={guestInputGroupBlockNoWrapStyle}>
              <GuestPeopleBlock
                groupName={`guests.${index}`}
                maxNumberOfChildren={MAX_NUMBER_OF_CHILDREN}
                maxNumberOfAdult={MAX_NUMBER_OF_ADULT}
                labelColorStyleKey={labelColorStyleKey}
                borderColorStyleKey={borderColorStyleKey}
                errors={errors}
              />
            </div>
            <GuestChildrenBlock
              groupName={`guests.${index}`}
              maxAgeOfChildren={MAX_AGE_OF_CHILDREN}
              numberOfRooms={values.number_of_rooms}
              ageInputWidthStyle={isGuestMultipleColumn ? "33.333%" : null}
              borderColorStyleKey={borderColorStyleKey}
              errors={errors}
            />
          </div>
        ))}
      </React.Fragment>
    );
  };

  return (
    <div css={guestBlockStyle}>
      <div css={guestInputGroupBlockStyle}>
        <div css={guestInputGroupBlockStyle}>
          {renderNumberOfRooms()}
          {Number(values.number_of_rooms || 1) === 1
            ? renderGuestsFields()
            : ""}
        </div>
      </div>
      {Number(values.number_of_rooms || 1) > 1 ? (
        <div css={multiGuestBlockStyle}>{renderGuestsFieldsWithMulti()}</div>
      ) : (
        ""
      )}
    </div>
  );
};

const areEqual: any = (prevProps: any, nextProps: any): any => {
  return isEqual(prevProps, nextProps);
};
export const GuestsBlock: any = React.memo(GuestsBlockComponent, areEqual);

const guestBlockStyle = css`
  display: flex;
  flex-wrap: wrap;
  padding: ${space.atom} 0;
`;
const guestInputGroupForMultiBlockStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${moreThanBreakpoint("desktop")} {
    width: 50%;
  }
`;
const guestInputGroupBlockStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const guestInputGroupBlockNoWrapStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
`;
const guestInputBlockStyle = css`
  width: 50%;
`;
const guestInputGroupBlockMediumStyle = css`
  width: 66.666%;
  display: flex;
`;
const guestNumBlockStyle = css`
  width: 33.333%;
`;
// const guestInputBlockSmallStyle = css`
//   width: 33.333%;
//   ${moreThanBreakpoint("tablet")} {
//     width: 25%;
//   }
// `;
// const childLabelStyle = css`
//   color: ${accentColor.primaryColor};
//   font-weight: ${fontWeight.regular};
// `;
const guestLabelStyle = css`
  color: #fff;
  background-color: ${accentColor.secondaryColor};
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.label};
  padding: ${space.atom0_5x} ${space.atom} 0.2rem;
  margin: ${space.atom};
`;
const multiGuestBlockStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;
